html,
body,
#root,
.app {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}
